<template>
  <div class="icon-bg">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4V19Z"
        fill="#A7A9C0"
      />
      <path
        opacity="0.3"
        d="M8.72954 14.684C8.35181 15.0869 7.71897 15.1073 7.31606 14.7295C6.91315 14.3518 6.89274 13.719 7.27047 13.3161L11.0205 9.31607C11.3858 8.92641 11.9928 8.89261 12.3991 9.23932L15.3589 11.765L19.2151 6.88037C19.5573 6.44689 20.1862 6.37291 20.6196 6.71513C21.0531 7.05735 21.1271 7.68618 20.7849 8.11965L16.2849 13.8197C15.9334 14.2649 15.2824 14.3289 14.8509 13.9607L11.8268 11.3802L8.72954 14.684Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconStatistics",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
